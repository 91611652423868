<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-card class="pa-3">
      <v-row>
        <v-col cols="12" sm="5" md="4" class="pt-8">
          <!-- <v-img :src="getImage(product.image)"> </v-img> -->
          <v-carousel height="auto" cycle hide-delimiter-background show-arrows-on-hover>
            <v-carousel-item :src="imgurl + product.image"></v-carousel-item>
            <v-carousel-item :src="imgurl + product.image2"></v-carousel-item>
            <v-carousel-item :src="imgurl + product.image3"></v-carousel-item>
          </v-carousel>
        </v-col>
        <v-col cols="12" sm="7" md="8">
          <v-card-title>
            {{ product.brand }} {{ product.type }}
            {{ product.product_name }} ({{ product.product_code }})
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>Barcode</td>
                  <td>{{ product.barcode }}</td>
                </tr>
                <tr>
                  <td>Nama</td>
                  <td>{{ product.product_name }}</td>
                </tr>
                <tr>
                  <td>Kategori</td>
                  <td>{{ product.category }}</td>
                </tr>
                <tr>
                  <td>Tipe</td>
                  <td>{{ product.type }}</td>
                </tr>
                <tr>
                  <td>Merk</td>
                  <td>{{ product.brand }}</td>
                </tr>
                <tr>
                  <td>Stok</td>
                  <td>{{ product.stock }}</td>
                </tr>
                <tr>
                  <td>Satuan</td>
                  <td>{{ product.unit }}</td>
                </tr>
                <tr>
                  <td colspan="2">
                    Deskripsi :<br />
                    {{ product.description }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions class="mx-auto" style="width: 50%; align: center">
            <v-btn
              block
              color="success"
              @click="buy"
              :disabled="product.stock <= 0"
            >
              <v-icon>mdi-cart-plus</v-icon> &nbsp; Masukkan Keranjang
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>

    <!-- <br />
    <v-card>
      <v-img :src="getImage(product.image)" height="400px"> </v-img>
      <v-card-title>
        {{ product.brand }} {{ product.type }} {{ product.product_name }} ({{
          product.product_code
        }})
      </v-card-title>
      <v-card-text>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td><v-icon>mdi-account-tie</v-icon> Author</td>
              <td>{{ product.brand }}</td>
            </tr>
            <tr>
              <td><v-icon>mdi-bullhorn</v-icon> Publisher</td>
              <td>{{ product.brand }}</td>
            </tr>
            <tr>
              <td><v-icon>mdi-weight</v-icon> Weight</td>
              <td>{{ product.brand }} kg</td>
            </tr>
            <tr>
              <td><v-icon>mdi-format-list-bulleted</v-icon> Stock</td>
              <td>{{ product.stock }} item</td>
            </tr>
            <tr>
              <td><v-icon>mdi-eye</v-icon> Views</td>
              <td>{{ product.views }}</td>
            </tr>
            <tr>
              <td><v-icon>mdi-cash</v-icon> Price</td>
              <td class="orange--text">Rp {{ product.brand }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        Description: <br />
        {{ product.desc }}
        <br />
        Categories:
        <v-chip
          v-for="category in product.categories"
          :key="category.id"
          :to="'/category/' + category.slug"
          small
        >
          {{ category.name }}
        </v-chip>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          color="success"
          @click="buy"
          :disabled="product.stock == 0"
        >
          <v-icon>mdi-cart-plus</v-icon> &nbsp; BUY
        </v-btn>
      </v-card-actions>
    </v-card> -->
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    product: [], // objek product
    imgurl: process.env.VUE_APP_BACKEND_URL + "/assets/uploads/products/",
    items: [
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/sky.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/bird.jpg",
      },
      {
        src: "https://cdn.vuetifyjs.com/images/carousel/planet.jpg",
      },
    ],
  }),
  created() {
    this.go();
  },
  methods: {
    ...mapActions({
      addCart: "cart/add",
      setAlert: "alert/set",
    }),
    buy() {
      // alert('buy')
      // this.$store.dispatch('add', this.product)
      this.addCart(this.product);

      this.setAlert({
        status: true,
        color: "success",
        text: "Produk telah ditambahkan ke keranjang",
      });
    },
    go() {
      this.$vloading.show();
      // console.log(this.$route);  //should return object
      let { slug } = this.$route.params;
      // console.log(slug);
      let url = "/product_details/" + slug;
      // url = url + "?page=" + this.page;
      // url = encodeURI(url);
      this.axios
        .get(url)
        .then((response) => {
          this.$vloading.hide();
          let { data } = response;
          this.product = data;
          // console.log(data);
        })
        .catch((error) => {
          this.$vloading.hide();
          let { responses } = error;
          console.log(responses);
        });
    },
  },
};
</script>