<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date | moment("DD MMMM YYYY") }}
      </template>
      <template v-slot:item.product="{ item }">
        {{ item.brand }} {{ item.type }} {{ item.product_name }} ({{
          item.product_code
        }})
      </template>
      <template v-slot:item.qty="{ item }">
        {{ item.qty }} {{ item.unit }}
      </template>
      <!-- <template v-slot:item.category_id="{ item }">
      <span v-for="dt in produk" :key="dt.id">
        <span v-if="dt.id == item.category_id">
          {{ dt.category }}
        </span>
      </span>
    </template> -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Barang Reject</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#FCEC27" class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <!-- <v-select
                        v-model="editedItem.category_id"
                        :items="produk"
                        :rules="rules"
                        dense
                        item-value="id"
                        item-text="category"
                        label="Pilih produk"
                      ></v-select> -->
                      <v-autocomplete
                        v-model="barcode"
                        :items="produk"
                        :rules="rules"
                        dense
                        item-value="product_id"
                        item-text="barcode"
                        label="Pilih produk"
                        clearable
                        autofocus
                        auto-select-first
                        v-on:change="barcodeChange"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" class="pa-0">
                      <v-simple-table dense>
                        <thead>
                          <tr>
                            <td colspan="2">
                              <strong><u>Informasi Produk</u></strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Produk</td>
                            <td>{{ nama_produk }}</td>
                          </tr>
                          <tr>
                            <td>Kategori</td>
                            <td>{{ kategori_produk }}</td>
                          </tr>
                          <tr>
                            <td>Stok Saat Ini</td>
                            <td>{{ stok_produk }}</td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="qty"
                        :rules="rules"
                        type="number"
                        label="Jumlah Stok Keluar"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="desc"
                        :rules="rules"
                        label="Keterangan"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <!-- <v-card>
            <v-card-title class="text-h5"
              >Anda yakin ingin menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card> -->
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{}">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogLoading: false,
    dialogDelete: false,
    search: "",
    nama_produk: "",
    kategori_produk: "",
    stok_produk: "",
    unit_produk: "",
    barcode: "",
    qty: "",
    desc: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "Tanggal Keluar",
        align: "left",
        value: "date",
      },
      { text: "Barcode", value: "barcode" },
      { text: "Produk", value: "product" },
      { text: "Jumlah", value: "qty" },
      { text: "Keterangan", value: "history_desc" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    produk: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getStokKeluar();
      this.getProduct();
    },

    getStokKeluar() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/stock_history/type/out", config)
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getProduct() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/product", config)
        .then((response) => {
          // console.log(response);
          this.produk = response.data.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    barcodeChange: function (e) {
      console.log(e);
      if (e !== null) {
        this.getProductById(e);
      }
    },

    getProductById(e) {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/product/" + e, config)
        .then((response) => {
          this.dialogLoading = false;
          console.log(response.data.data);
          this.nama_produk =
            response.data.data.brand +
            " " +
            response.data.data.type +
            " " +
            response.data.data.product_name +
            " (" +
            response.data.data.product_code +
            ")";
          this.kategori_produk = response.data.data.category;
          this.stok_produk =
            response.data.data.stock + " " + response.data.data.unit;
        })
        .catch((error) => {
          this.dialogLoading = false;
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/productname/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getStokKeluar();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("user_id", this.user.data.user_id);
        formData.set("product_id", this.barcode);
        formData.set("qty", this.qty);
        formData.set("type", "out");
        formData.set("desc", this.desc);
        this.axios
          .post("/stock_history", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Stok Berhasil Ditambahkan!",
            });
            this.close();
            this.getStokKeluar();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },
  },
};
</script>