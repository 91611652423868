<template>
  <div>
    <v-row>
      <v-col cols="12" md="8" sm="6">
        <v-card class="overflow-hidden">
          <v-toolbar flat color="primary" dark>
            <v-icon>mdi-account</v-icon>
            <v-toolbar-title class="font-weight-light">
              My Profile
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#FCEC27" fab small @click="isEditing = !isEditing">
              <v-icon v-if="isEditing" color="primary"> mdi-close </v-icon>
              <v-icon v-else color="primary"> mdi-pencil </v-icon>
            </v-btn>
          </v-toolbar>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-text>
              <!-- <v-text-field
                :disabled="!isEditing"
                color="white"
                label="Name"
              ></v-text-field>
              <v-autocomplete
                :disabled="!isEditing"
                :items="states"
                :filter="customFilter"
                color="white"
                item-text="name"
                label="State"
              ></v-autocomplete> -->
              <v-text-field
                :disabled="!isEditing"
                v-model="nip"
                :rules="rules"
                label="NIP"
                type="number"
                required
                append-icon="mdi-account-key"
              ></v-text-field>
              <v-text-field
                :disabled="!isEditing"
                v-model="full_name"
                :rules="rules"
                label="Nama Lengkap"
                required
                append-icon="mdi-account"
              ></v-text-field>
              <v-text-field
                :disabled="!isEditing"
                v-model="phone"
                :rules="rules"
                label="Nomor Handphone"
                required
                append-icon="mdi-cellphone"
              ></v-text-field>
              <v-select
                :disabled="!isEditing"
                v-model="position_id"
                :items="position"
                :rules="rules"
                dense
                item-value="id"
                item-text="position_name"
                label="Jabatan"
              ></v-select>
              <v-select
                :disabled="!isEditing"
                v-model="division_id"
                :items="division"
                :rules="rules"
                dense
                item-value="id"
                item-text="division_name"
                label="Bidang"
                v-on:change="divisionChange"
              ></v-select>
              <v-select
                :disabled="!isEditing"
                v-model="section_id"
                :items="section"
                :rules="rules"
                dense
                item-value="id"
                item-text="section_name"
                label="Seksi"
              ></v-select>
              <v-text-field
                :disabled="!isEditing"
                v-model="grade"
                :rules="rules"
                label="Pangkat/Golongan"
                required
                append-icon="mdi-seal"
              ></v-text-field>
              <v-text-field
                :disabled="!isEditing"
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                append-icon="mdi-email"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!isEditing || !valid"
                color="success"
                @click="save"
              >
                <v-icon left dark>mdi-content-save-edit</v-icon>
                Simpan
              </v-btn>
            </v-card-actions>
          </v-form>
          <v-snackbar
            v-model="hasSaved"
            :timeout="2000"
            color="success"
            absolute
            bottom
            left
          >
            Your profile has been updated
          </v-snackbar>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Foto Profile</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-container fluid>
            <v-form ref="form" v-model="validFoto" lazy-validation>
              <v-row align="center">
                <v-col cols="12" class="text-center">
                  <v-avatar size="150">
                    <v-img :src="getAvatar(photo)" style="width: 100%"></v-img>
                  </v-avatar>
                </v-col>
                <v-col cols="12" class="text-center">
                  (Max. 1 MB)
                  <input
                    type="file"
                    id="file"
                    ref="file"
                    accept="image/*"
                    v-on:change="handleFileUpload()"
                    style="color: black; background-color: #ddd"
                  />
                  <div class="text-center pt-4">
                    <v-btn
                      small
                      color="#FCEC27"
                      :disabled="!validFoto"
                      @click="updateFoto"
                    >
                      <v-icon left dark>mdi-circle-edit-outline</v-icon>
                      Ubah Foto
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container> </v-card
        ><br />
        <v-card>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Ubah Password</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-container fluid>
            <v-form ref="form" v-model="validPassword" lazy-validation>
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    label="Password Baru"
                    counter
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>
                  <v-text-field
                    v-model="confpassword"
                    :append-icon="showconfPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="confpasswordRules.concat(passwordConfirmationRule)"
                    :type="showconfPassword ? 'text' : 'password'"
                    label="Konfirmasi Password Baru"
                    counter
                    @click:append="showconfPassword = !showconfPassword"
                  ></v-text-field>
                  <div class="text-center">
                    <v-btn
                      small
                      color="#FCEC27"
                      :disabled="!validPassword"
                      @click="updatePassword"
                    >
                      <v-icon left dark>mdi-update</v-icon>
                      Ubah Password
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    valid: true,
    validFoto: true,
    validPassword: true,
    hasSaved: false,
    isEditing: null,
    model: null,
    states: [
      { name: "Florida", abbr: "FL", id: 1 },
      { name: "Georgia", abbr: "GA", id: 2 },
      { name: "Nebraska", abbr: "NE", id: 3 },
      { name: "California", abbr: "CA", id: 4 },
      { name: "New York", abbr: "NY", id: 5 },
    ],

    // INISIASI FORM
    user_id: "",
    nip: "",
    full_name: "",
    phone: "",
    email: "",
    position_id: "",
    position_name: "",
    division_id: "",
    division_name: "",
    division_code: "",
    section_id: "",
    section_name: "",
    grade: "",
    status: "",
    role: "",
    photo: "",
    showPassword: false,
    password: "",
    showconfPassword: false,
    confpassword: "",

    position: [],
    division: [],
    section: [],

    // RULES
    rules: [(v) => !!v || "Form ini wajib di isi"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) =>
        /([a-zA-Z0-9_]{1,})(@)([a-zA-Z0-9_]{2,}).([a-zA-Z0-9_]{2,})+/.test(v) ||
        "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password wajib di isi",
      (v) => (v && v.length >= 8) || "Minimal 8 karakter",
    ],
    confpasswordRules: [
      (v) => !!v || "Konfirmasi Password wajib di isi",
      (v) => (v && v.length >= 8) || "Minimal 8 karakter",
    ],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getProfile();
      this.getPosition();
      this.getDivision();
    },

    getProfile() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/user/" + this.user.data.user_id, config)
        .then((response) => {
          console.log(response.data);
          // this.datas = response.data;
          this.user_id = response.data[0].user_id;
          this.nip = response.data[0].nip;
          this.full_name = response.data[0].full_name;
          this.phone = response.data[0].phone;
          this.email = response.data[0].email;
          this.position_id = response.data[0].position_id;
          this.position_name = response.data[0].position_name;
          this.division_id = response.data[0].division_id;
          this.division_name = response.data[0].division_name;
          this.division_code = response.data[0].division_code;
          this.section_id = response.data[0].section_id;
          this.section_name = response.data[0].section_name;
          this.grade = response.data[0].grade;
          this.status = response.data[0].status;
          this.role = response.data[0].role;
          this.photo = response.data[0].photo;
          this.$vloading.hide();
          this.getSection(this.division_id);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getPosition() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/positions", config)
        .then((response) => {
          // console.log(response);
          this.position = response.data;
          // console.log(response.data.data);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getDivision() {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/divisions", config)
        .then((response) => {
          // console.log(response);
          this.division = response.data;
          // console.log(response.data.data);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    divisionChange: function (e) {
      // console.log(e);
      this.getSection(e);
    },

    getSection(item) {
      this.$vloading.show();
      let config = {
        headers: {},
      };
      this.axios
        .get("/sections", config)
        .then((response) => {
          this.$vloading.hide();

          var Data = response.data;
          var newData = Data.filter((e) => e.division_id == item);
          this.section = newData;
          // console.log(newData);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.productname = [];
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.abbr.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    save() {
      this.isEditing = !this.isEditing;

      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("nip", this.nip);
      formData.set("full_name", this.full_name);
      formData.set("phone", this.phone);
      formData.set("position_id", this.position_id);
      formData.set("division_id", this.division_id);
      formData.set("section_id", this.section_id);
      formData.set("grade", this.grade);
      formData.set("email", this.email);
      formData.set("role", this.role);
      this.axios
        .post("/user/profil/" + this.user.data.user_id, formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          // this.setAlert({
          //   status: true,
          //   color: "success",
          //   text: "Data Berhasil Diubah!",
          // });
          // this.close();
          this.hasSaved = true;
          this.getProfile();
          this.getPosition();
          this.getDivision();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.getProfile();
          this.getPosition();
          this.getDivision();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    updateFoto() {
      this.$vloading.show();
      const config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("photo", this.file);
      this.axios
        .post("/user/update_photo/" + this.user.data.user_id, formData, config)
        .then(() => {
          this.$vloading.hide();
          this.getProfile();
          this.getPosition();
          this.getDivision();
          // let { data } = response;
          // console.log(data);
          // this.setAuth(data);
          this.setAlert({
            status: true,
            color: "success",
            text: "Foto Profile Berhasil Diubah!",
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.getProfile();
          this.getPosition();
          this.getDivision();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },
    updatePassword() {
      this.$vloading.show();
      const config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("password", this.password);
      formData.set("password_confirm", this.password);
      this.axios
        .post(
          "/user/update_password/" + this.user.data.user_id,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.getProfile();
          this.getPosition();
          this.getDivision();
          // let { data } = response;
          // console.log(data);
          // this.setAuth(data);
          this.setAlert({
            status: true,
            color: "success",
            text: "Password Berhasil Diubah!",
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.getProfile();
          this.getPosition();
          this.getDivision();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },
    close() {
      this.$emit("closed", false);
    },
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
  },
};
</script>