<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-container class="mb-2" grid-list-sm>
      <v-subheader> Semua Kategori </v-subheader>
      <v-layout wrap>
        <v-flex
          v-for="category in categories"
          :key="`category-` + category.id"
          xs12
          sm12
          md6
          lg6
        >
         <v-hover v-slot="{ hover }">
          <v-card :to="'/category/' + category.id" class="ma-1" :elevation="hover ? 16 : 2" :class="{ 'on-hover': hover }">
            <v-card-title v-line-clamp:20="2"
              ><v-avatar size="35">
                <v-img
                  :src="getIcon(category.icon)"
                  style="width: 100%"
                ></v-img>
              </v-avatar>
              &nbsp; <small>{{ category.category }}</small></v-card-title
            >
          </v-card>
         </v-hover>
        </v-flex>
      </v-layout>
      <!-- <v-row align="center">
        <v-col
          cols="12"
          sm="6"
          md="3"
           v-for="category in categories"
            :key="`category-` + category.id"
        >
          <v-card :to="'/category/' + category.id" class="ma-1">
              <v-card-title
                ><small>{{ category.category }}</small></v-card-title
              >
            </v-card>
        </v-col>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    categories: [],
    page: 0,
    lengthPage: 0,
  }),
  created() {
    this.go();
  },
  methods: {
    go() {
      let url = "/all_category";
      this.axios
        .get(url)
        .then((response) => {
          let { data } = response;
          let { meta } = response.data;
          this.categories = data;
          this.page = meta.current_page;
          this.lengthPage = meta.last_page;
        })
        .catch((error) => {
          let { responses } = error;
          console.log(responses);
        });
    },
  },
};
</script>