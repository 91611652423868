<template>
  <div>
    <v-alert dense text type="info">
      Tidak dapat menemukan barang yang kamu inginkan di menu Home? Pesan
      Barangmu disini agar dapat ditambahkan oleh Admin.
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.date="{ item }">
        {{ item.date | moment("DD MMMM YYYY") }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.request_status == 1">
          <v-chip small rounded color="grey" dark> Menunggu Konfirmasi </v-chip>
        </span>
        <span v-if="item.request_status == 2">
          <v-chip small rounded color="green" dark> Dikonfirmasi </v-chip>
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Pesan Barang</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#FCEC27" class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editedItem.messages"
                        :rules="rules"
                        label="Pesan Barang"
                        placeholder="Tulis Barang yang ingin kamu pesan disini"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogSelesai" max-width="500px">
            <v-card>
              <v-card-title class="text-h5 justify-center"
                >Konfirmasi data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Batal</v-btn>
                <v-btn color="blue darken-1" text @click="saveSelesai"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <span v-if="user.data.role == 1">
          <v-btn
            x-small
            color="success"
            @click="selesaiItem(item)"
            v-if="item.request_status < 2"
            fab
            rounded
          >
            <v-icon small>mdi-check </v-icon>
          </v-btn>
        </span>
        <span v-else>
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="item.request_status < 2"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click="deleteItem(item)"
            v-if="item.request_status < 2"
          >
            mdi-delete
          </v-icon>
        </span>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogSelesai: false,
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Tanggal", value: "date" },
      {
        text: "Isi Pesan",
        align: "left",
        value: "messages",
      },
      { text: "Pemesan", value: "full_name" },
      { text: "Bidang", value: "division_name" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getRequest();
    },

    getRequest() {
      if (this.user.data.role == 1) {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        this.axios
          .get("/master/product/requests", config)
          .then((response) => {
            // console.log(response);
            this.datas = response.data;
            this.$vloading.hide();
          })
          .catch((error) => {
            this.$vloading.hide();
            console.log(error.response.status);
            if (error.response.status == 401) {
              this.setAuth({}); // kosongkan auth ketika logout
              // this.setAlert({
              //   status: true,
              //   color: "error",
              //   text: "Login First!",
              // });
              this.$router.replace({ name: "Home" });
            } else {
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            }
          });
      } else {
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        this.axios
          .get("/master/product/request/user/" + this.user.data.user_id, config)
          .then((response) => {
            // console.log(response);
            this.datas = response.data;
            this.$vloading.hide();
          })
          .catch((error) => {
            this.$vloading.hide();
            console.log(error.response.status);
            if (error.response.status == 401) {
              this.setAuth({}); // kosongkan auth ketika logout
              // this.setAlert({
              //   status: true,
              //   color: "error",
              //   text: "Login First!",
              // });
              this.$router.replace({ name: "Home" });
            } else {
              this.setAlert({
                status: true,
                color: "error",
                text: error.response.data.messages,
              });
              this.close();
            }
          });
      }
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    selesaiItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogSelesai = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete(
          "/master/product/request/" + this.editedItem.request_product_id,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getRequest();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.dialogSelesai = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("user_id", this.user.data.user_id);
        formData.set("messages", this.editedItem.messages);
        this.axios
          .post(
            "/master/product/request/" + this.editedItem.request_product_id,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Diubah!",
            });
            this.close();
            this.getRequest();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("user_id", this.user.data.user_id);
        formData.set("messages", this.editedItem.messages);
        this.axios
          .post("/master/product/request", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getRequest();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },

    saveSelesai() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("status", "2");
      this.axios
        .post(
          "/master/product/request/" +
            this.editedItem.request_product_id +
            "/status",
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dikonfirmasi!",
          });
          this.close();
          this.getRequest();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },
  },
};
</script>