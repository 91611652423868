<template>
  <v-data-table
    :headers="headers"
    :items="datas"
    sort-by="calories"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:item.nomor="{ item }">
      {{ datas.indexOf(item) + 1 }}
    </template>
    <template v-slot:item.status="{ item }">
      <span v-if="item.status == 'active'">
        <v-chip small rounded color="success" dark> {{ item.status }} </v-chip>
      </span>
      <span v-else>
        <v-chip small rounded color="error" dark> {{ item.status }} </v-chip>
      </span>
    </template>
    <template v-slot:item.role="{ item }">
      <span v-if="item.role == '1'">
        Admin
      </span>
      <span v-else>
        User Biasa
      </span>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Users</v-toolbar-title>
        </v-toolbar>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="#FCEC27" class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template> -->
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="editedItem.status"
                      :items="statusItems"
                      :rules="rules"
                      dense
                      item-value="id"
                      item-text="name"
                      label="Status"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRole" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Ubah Role</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-select
                      v-model="editedItem.role"
                      :items="roleItems"
                      :rules="rules"
                      dense
                      item-value="id"
                      item-text="name"
                      label="Role"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
              <v-btn color="blue darken-1" text @click="saveRole">
                Simpan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Reset password user ini?</v-card-title
            >
            <v-card-text>
              <strong>Password akan direset menjadi NIP user.</strong>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
    </template>
    <template v-slot:item.actions="{ item }">
      <!-- <v-btn x-small color="info" @click="editItem(item)"
        ><v-icon small class="mr-2"> mdi-pencil </v-icon>
        Status
      </v-btn> -->
      <v-btn x-small class="mr-1" color="info" dark @click="editItem(item)">
        <v-icon x-small>mdi-pencil</v-icon>&nbsp;Status
      </v-btn>
      <v-btn x-small color="warning" dark @click="deleteItem(item)">
        <v-icon x-small>mdi-update</v-icon>&nbsp;Password
      </v-btn>
      <v-btn x-small class="mr-1" color="teal" dark @click="roleItem(item)">
        <v-icon x-small>mdi-account-cog</v-icon>&nbsp;Role
      </v-btn>
      <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
    </template>
    <template v-slot:no-data> Tidak ada data </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogRole: false,
    dialogDelete: false,
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      {
        text: "NIP",
        align: "left",
        value: "nip",
      },
      { text: "Nama", value: "full_name" },
      { text: "No HP", value: "phone" },
      { text: "Email", value: "email" },
      { text: "Jabatan", value: "position_name" },
      { text: "Bidang", value: "division_name" },
      { text: "Seksi", value: "section_name" },
      { text: "Pangkat/ Golongan", value: "grade" },
      { text: "Role", value: "role" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    statusItems: [
      { id: "active", name: "active" },
      { id: "inactive", name: "inactive" },
    ],
    roleItems: [
      { id: "1", name: "Admin" },
      { id: "4", name: "User Biasa" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Ubah Status";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogRole(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getUser();
    },

    getUser() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/user", config)
        .then((response) => {
          // console.log(response);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    roleItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRole = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      const config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("password", this.editedItem.nip);
      formData.set("password_confirm", this.editedItem.nip);
      this.axios
        .post(
          "/user/update_password/" + this.editedItem.user_id,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          this.getUser();
          this.closeDelete();
          // let { data } = response;
          // console.log(data);
          // this.setAuth(data);
          this.setAlert({
            status: true,
            color: "success",
            text: "Password Berhasil Direset!",
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          this.getUser();
          this.closeDelete();
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    close() {
      this.dialog = false;
      this.dialogRole = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("status", this.editedItem.status);
        this.axios
          .post(
            "/user/update_status/" + this.editedItem.user_id,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Status User Berhasil Diubah!",
            });
            this.close();
            this.getUser();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("position_name", this.editedItem.position_name);
        formData.set("position_code", this.editedItem.position_code);
        this.axios
          .post("/master/position", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getUser();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },

    saveRole() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("role", this.editedItem.role);
        this.axios
          .post(
            "/user/update_role/" + this.editedItem.user_id,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Role User Berhasil Diubah!",
            });
            this.close();
            this.getUser();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },
  },
};
</script>