<template>
  <div>
    <v-card>
      <v-toolbar color="#FCEC27">
        <v-toolbar-title>Monitoring Distribusi Produk</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container fluid class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row align="center">
            <v-col cols="12">
              <v-select
                v-model="bidang"
                :items="bidangItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="division_name"
                label="Bidang"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="bulan"
                :items="bulanItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="name"
                label="Dari Bulan"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="bulanSampai"
                :items="bulanItems"
                :rules="rules"
                dense
                item-value="id"
                item-text="name"
                label="Sampai Bulan"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="tahun"
                :rules="rules"
                label="Tahun"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn color="#FCEC27" :disabled="!valid" @click="submit">
                  <v-icon left dark>mdi-text-search</v-icon>
                  Lihat
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card>
    <br />
    <v-data-table
      :headers="headers"
      :items="datas"
      class="elevation-1"
      item-key="name"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.brand="{ item }">
        {{ item.brand }} {{ item.type }} {{ item.product_name }}
      </template>
      <!-- <template v-slot:item.qty="{ item }">
        {{ item.qty }} {{ item.unit }}
      </template> -->
      <!-- <template v-slot:item.pesanan="{ item }">
        {{ item.total.product }} Produk ({{ item.total.qty }} Items)<br>
        <v-simple-table class="mt-2 mb-2" dense fixed-header height="100px">
          <thead>
            <tr>
              <th>No</th>
              <th>Produk</th>
              <th>Kuantitas</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="dt in item.product" :key="dt.id">
              <td>{{ item.product.indexOf(dt) + 1 }}</td>
              <td>
                {{ dt.brand }} {{ dt.type }} {{ dt.product_name }}
              </td>
              <td>{{ dt.qty }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </template> -->
      <template v-slot:top>
        <v-toolbar dark color="primary">
          <v-toolbar-title
            >Hasil Monitoring
            <download-excel
              class="btn btn-default"
              :data="datas"
              :fields="json_fields"
              worksheet="My Worksheet"
              :name="'monitoring_'+bulan+'-'+bulanSampai+'_'+tahun+'.xls'"
            >
              <v-btn small color="teal" dark class="mb-2">
                <v-icon left>mdi-download</v-icon>Excel
              </v-btn>
            </download-excel>
            <!-- <v-btn small color="teal" dark class="mb-2" @click="makePDF">
                <v-icon left>mdi-download</v-icon>Download
              </v-btn> -->
          </v-toolbar-title>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <!-- <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template> -->
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <!-- <br />
    <div id="print" hidden>
      <center>
        <table
          border="1"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">Jalur Penerimaan</th>
              <th class="text-left">Sekolah</th>
              <th class="text-left">Nomor Registrasi</th>
              <th class="text-left">Nama Peserta Didik</th>
              <th class="text-left">Status Registrasi Ulang</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="key in admission" :key="key.id">
              <td>{{ admission.indexOf(key) + 1 }}</td>
              <td>{{ key.admission.admission.NAME }}</td>
              <td>{{ key.school.NAME }}</td>
              <td>{{ key.admission.REGNUMBER }}</td>
              <td>{{ key.student.NAME }}</td>
              <td>
                <span v-if="key.registered != null">
                  Sudah Registrasi Ulang
                </span>
                <span v-else> Belum Registrasi Ulang </span>
              </td>
            </tr>
          </tbody>
        </table>
      </center>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "MappingData",
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    menu: false,
    dialogLoading: false,
    menu2: false,
    dialog: false,
    dialogDelete: false,
    valid: true,
    search: "",
    status: "",
    bidang: "all",
    bulan: new Date().getMonth() + 1,
    bulanSampai: new Date().getMonth() + 1,
    tahun: new Date().getFullYear(),
    headers: [
      { text: "No", value: "nomor", width: "5%" },
      { text: "Nama Barang", value: "brand" },
      { text: "Kode Barang", value: "product_code" },
      { text: "Kuantitas", value: "qty" },
      { text: "Bidang", value: "division_name" },
      { text: "Nama Pemesan", value: "full_name" },
      //   { text: "Actions", value: "actions", sortable: false },
    ],
    // exceldepan: "monitoring_",
    // excelblkg: "monitoring_",
    datas: [],
    json_fields: {
      // "No": datas.indexOf(item) + 1,
      // "Nama Barang": "brand",
      "Nama Barang": {
        callback: (value) => {
          return `${value.brand} ${value.type} ${value.product_name}`;
        },
      },
      "Kode Barang": "product_code",
      Kuantitas: "qty",
      Bidang: "division_name",
      "Nama Pemesan": "full_name",
    },
    bidangItems: [],
    bulanItems: [
      { id: 1, name: "Januari" },
      { id: 2, name: "Februari" },
      { id: 3, name: "Maret" },
      { id: 4, name: "April" },
      { id: 5, name: "Mei" },
      { id: 6, name: "Juni" },
      { id: 7, name: "Juli" },
      { id: 8, name: "Agustus" },
      { id: 9, name: "September" },
      { id: 10, name: "Oktober" },
      { id: 11, name: "November" },
      { id: 12, name: "Desember" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    statusItems: [
      { id: 1, name: "Dibuka" },
      { id: 0, name: "Ditutup" },
    ],
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
    passwordConfirmationRule() {
      return () => this.password === this.confpassword || "Password harus sama";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getBidang();
    },

    getBidang() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/master/division_all", config)
        .then((response) => {
          // console.log(response);
          // this.bidangItems = response.data;

          var Data = response.data;
          Data.unshift({
            id: "all",
            division_code: "ALL",
            division_name: "Semua Bidang",
          });
          this.bidangItems = Data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    submit() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get(
          "/dashboard/transaction/division/" +
            this.bidang +
            "/fromMonth/" +
            this.bulan +
            "/toMonth/" +
            this.bulanSampai +
            "/year/" +
            this.tahun,
          config
        )
        .then((response) => {
          console.log(response.data);
          this.datas = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
            // this.namaexcel =
            //   "monitoring_" +
            //   this.bulan +
            //   "-" +
            //   this.bulanSampai +
            //   "_" +
            //   this.tahun +
            //   ".xls";
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/category/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getBidang();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("division_name", this.editedItem.division_name);
        formData.set("division_code", this.editedItem.division_code);
        this.axios
          .post("/master/division/" + this.editedItem.id, formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Diubah!",
            });
            this.close();
            this.getBidang();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("division_name", this.editedItem.division_name);
        formData.set("division_code", this.editedItem.division_code);
        this.axios
          .post("/master/division", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getBidang();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },
  },
};
</script>