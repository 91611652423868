<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <v-btn small text to="/admin-spmb" class="blue--text">
        <v-icon>mdi-chevron-left</v-icon> Kembali
      </v-btn>
    </v-container>
    <div id="print" v-for="dt in data" :key="dt.id">
      <center>
        <table
          border="0"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td style="width: 20%" align="right">
              <img width="100px" src="/img/logo/logo-bon.png" />
            </td>
            <td align="center" style="line-height: 1em">
              <span style="font-size: 13px; line-height: 1.5em"
                ><b>KEMENTERIAN KEUANGAN REPUBLIK INDONESIA</b></span
              ><br />
              <span style="font-size: 11px; line-height: 1.5em">
                <b
                  >DIREKTORAT JENDERAL PAJAK<br />
                  KANTOR WILAYAH DIREKTORAT JENDERAL PAJAK SULAWESI UTARA,<br />
                  TENGAH, GORONTALO, DAN MALUKU UTARA</b
                ></span
              ><br />
              <span style="font-size: 7px">
                JALAN 17 AGUSTUS NO. 17 MANADO 95119<br />
                TELEPON (0431) 851785; 862742; FAKSIMILE (0431) 851803; LAMAN
                www.pajak.go.id<br />
                LAYANAN INFORMASI DAN PENGADUAN KRING PAJAK (021) 1500200<br />
                SUREL pengaduan@pajak.go.id; informasi@pajak.go.id
              </span>
            </td>
            <td style="width: 15%">&nbsp;</td>
          </tr>
        </table>
        <hr />
        <table
          border="0"
          width="95%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
            margin-top: 2px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td align="center">
              SURAT PERINTAH MENGELUARKAN BARANG<br />(SPMB)<br />
              NOMOR : {{ dt.spmb_number }}<br /><br />
              <table
                border="0"
                width="100%"
                style="
                  border-collapse: collapse;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 11px;
                "
                align="center"
              >
                <tr>
                  <td colspan="2">
                    Harap dikeluarkan barang persediaan tersebut dibawah ini
                    untuk keperluan :
                  </td>
                </tr>
                <tr>
                  <td style="width: 20%">Sub Bagian/Seksi</td>
                  <td>: {{ dt.customer[0].section_name }}</td>
                </tr>
                <tr>
                  <td>Bagian</td>
                  <td>: {{ dt.customer[0].division_name }}</td>
                </tr>
                <tr>
                  <td>Kanwil</td>
                  <td>
                    : Kanwil DJP Sulawesi Utara, Tengah, Gorontalo dan Maluku
                    Utara
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    Berdasarkan Surat Permintaan Barang No :
                    {{ dt.bill_number }}
                  </td>
                </tr>
              </table>
              <br />
              <table
                border="1"
                width="100%"
                style="
                  border-collapse: collapse;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 11px;
                  margin-top: 2px;
                "
                align="center"
                cellpadding="2px"
              >
                <tr>
                  <td colspan="6">&nbsp;</td>
                </tr>
                <tr>
                  <td align="center" rowspan="2">Nomor Urut</td>
                  <td align="center" colspan="3">Banyaknya</td>
                  <td align="center" rowspan="2">
                    Nama Sub-sub Kelompok Barang
                  </td>
                  <td align="center" rowspan="2">
                    Kode Sub-sub Kelompok Barang
                  </td>
                </tr>
                <tr>
                  <td align="center">Angka</td>
                  <td align="center">Huruf</td>
                  <td align="center">Satuan</td>
                </tr>
                <tr>
                  <td align="center" style="width: 5%">1</td>
                  <td align="center">2</td>
                  <td align="center">3</td>
                  <td align="center">4</td>
                  <td align="center">5</td>
                  <td align="center" style="width: 15%">6</td>
                </tr>
                <tr v-for="dts in dt.product" :key="dts">
                  <td align="center">{{ dt.product.indexOf(dts) + 1 }}</td>
                  <td align="center">{{ dts.qty }}</td>
                  <td align="center">{{ dts.qty_word }}</td>
                  <td align="center">{{ dts.unit }}</td>
                  <td>
                    &nbsp;{{ dts.brand }} {{ dts.type }} {{ dts.product_name }}
                  </td>
                  <td align="center">{{ dts.product_code }}</td>
                </tr>
              </table>
              <br />
              <table
                border="0"
                width="100%"
                style="
                  border-collapse: collapse;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 11px;
                  margin-top: 2px;
                "
                align="center"
                cellpadding="5px"
              >
                <tr>
                  <td style="width: 33%" align="center">
                    Diterima : {{ transaction_date | moment("DD MMMM YYYY")
                    }}<br />
                    Kasubbag/Kasi<br /><br />
                    <img
                      width="100px"
                      :src="getTTD(dt.employee1_ttd)"
                    /><br />
                    {{ dt.employee1 }}
                  </td>
                  <td style="width: 34%" align="center">
                    Dikeluarkan : {{ transaction_date | moment("DD MMMM YYYY")
                    }}<br />
                    Pejabat Pengurus Persediaan<br /><br />
                    <img
                      width="100px"
                      :src="getTTD(dt.employee2_ttd)"
                    /><br />
                    {{ dt.employee2 }}
                  </td>
                  <td style="width: 33%" align="center">
                    Manado, {{ transaction_date | moment("DD MMMM YYYY")
                    }}<br />
                    Kuasa Pengguna Barang<br /><br />
                    <img
                      width="100px"
                      :src="getTTD(dt.employee3_ttd)"
                      style="margin-top: 12px; margin-bottom: 12px"
                    /><br />
                    {{ dt.employee3 }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </center>
    </div>
    <br />
    <center v-if="data == ''">
      <v-alert dense text type="warning" width="50%">
        Transaksi ini belum ada SPMB.
      </v-alert>
      <v-btn medium color="info" dark @click="buatSPMB">
        <v-icon left>mdi-plus</v-icon>
        Buat SPMB
      </v-btn>
    </center>
    <center v-else>
      <v-btn medium color="success" dark @click="makePDF">
        <v-icon left>mdi-printer</v-icon>
        Cetak SPMB
      </v-btn>
    </center>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
export default {
  name: "Bon",
  data: () => ({
    nomor: "",
    kode_bidang: "",
    nama_bidang: "",
    barang: [],
    tgl_bon: "",
    jabatan: "",
    nama_pemesan: "",
    ttd: "",
    transaction_number: localStorage.getItem("transaction_number"),
    transaction_date: localStorage.getItem("transaction_date"),
    bill_number: localStorage.getItem("bill_number"),
    user_id: localStorage.getItem("user_id"),
    data: [],
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getSPMB();
    },

    getSPMB() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/spmb/" + this.transaction_number, config)
        .then((response) => {
          console.log(response.data);
          this.data = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    buatSPMB() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("transaction_number", this.transaction_number);
      formData.set("user_id", this.user_id);
      formData.set("bill_number", this.bill_number);
      formData.set("created_by", this.user.data.user_id);
      this.axios
        .post("/spmb", formData, config)
        .then(() => {
          this.$vloading.hide();
          this.getSPMB();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "SPMB Berhasil Dibuat!",
          });
          this.close();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    makePDF() {
      // window.html2canvas = html2canvas;
      // // var w = document.getElementById("print").offsetWidth;
      // // var h = document.getElementById("print").offsetHeight;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.getElementById("print"), {
      //   callback: function (pdf) {
      //     pdf.save("mypdf.pdf");
      //   },
      // });
      // var h = screen.height;
      // var w = screen.width;
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },
};
</script>

<style scoped>
/* #print {
  font-family: "Arial";
  font-size: 11px;
  margin-top: 10px;
  margin-left: 15px;
  width: 100%;
} */
</style>