<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.brand="{ item }">
        {{ item.brand }} {{ item.type }} {{ item.product_name }} ({{
          item.product_code
        }})
      </template>
      <template v-slot:item.stock="{ item }">
        {{ item.stock }} {{ item.unit }}
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary" class="pt-2">
          <v-toolbar-title class="pt-3"
            >Daftar Produk
            <span class="ml-3" style="float: right">
              <v-select
                v-model="stok"
                :items="itemsStok"
                label="Minimal Stok"
                dense
                outlined
                v-on:change="stokChange"
              ></v-select>
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-simple-table>
                      <tr>
                        <th colspan="2" align="left"><u>Informasi</u></th>
                      </tr>
                      <tr>
                        <td>Barcode</td>
                        <td>: {{ editedItem.barcode }}</td>
                      </tr>
                      <tr>
                        <td>Produk</td>
                        <td>
                          : {{ editedItem.brand }} {{ editedItem.type }}
                          {{ editedItem.product_name }} ({{
                            editedItem.product_code
                          }})
                        </td>
                      </tr>
                      <tr>
                        <td>Kategori</td>
                        <td>: {{ editedItem.category }}</td>
                      </tr>
                    </v-simple-table>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="editedItem.stock"
                      :rules="rules"
                      label="Stok"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Anda yakin ingin menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field> -->
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    stok: 5,
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Barcode", value: "barcode" },
      {
        text: "Produk",
        align: "left",
        value: "brand",
      },
      { text: "Kategori", value: "category" },
      { text: "Stok", value: "stock" },
      //   { text: "Deskripsi", value: "description", width: "15%" },
      //   { text: "Gambar", value: "image" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    kategori: [],
    unit: [],
    productname: [],
    type: [],
    statusItems: [
      { id: "1", status: "Belum Diproses" },
      { id: "2", status: "Sementara Diproses" },
      { id: "3", status: "Proses Pengantaran" },
      { id: "4", status: "Selesai" },
      { id: "5", status: "Dibatalkan" },
    ],
    itemsStok: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],

    series: [],
    chartOptions: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Ubah Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getProduct(this.stok);
    },

    stokChange: function (e) {
      //   console.log(e);
      this.getProduct(e);
    },

    getProduct(stok) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/product/min/" + stok, config)
        .then((response) => {
          // console.log(response);
          this.datas = response.data;
          console.log(this.datas);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/category/" + this.editedItem.id, config)
        .then(() => {
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getTransactions();
          this.closeDelete();
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("stock", this.editedItem.stock);
        this.axios
          .post(
            "/product/" + this.editedItem.product_id + "/stock",
            formData,
            config
          )
          .then(() => {
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Stok Berhasil Diubah!",
            });
            this.close();
            this.getProduct(this.stok);
          })
          .catch((error) => {
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
      }
      this.close();
    },

    detail(item) {
      // console.log(item.transaction_number);
      // console.log(item.customer[0].full_name);
      localStorage.route_id = "";
      localStorage.transaction_id = item.transaction_id;
      localStorage.transaction_number = item.transaction_number;
      localStorage.transaction_date = item.transaction_date;
      // localStorage.status = item.status;
      localStorage.nip = item.customer[0].nip;
      localStorage.full_name = item.customer[0].full_name;
      localStorage.phone = item.customer[0].phone;
      localStorage.email = item.customer[0].email;
      localStorage.position_id = item.customer[0].position_name;
      localStorage.division_id = item.customer[0].division_name;
      localStorage.section_id = item.customer[0].section_name;
      localStorage.grade = item.customer[0].grade;
      localStorage.product = item.total.product;
      localStorage.qty = item.total.qty;
      this.$router.replace({ name: "Transaction Detail" });
    },
  },
};
</script>