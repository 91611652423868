<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left">
        <v-btn small text to="/" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-container class="mb-2" grid-list-sm>
      <v-subheader> Semua Produk </v-subheader>
      <v-layout wrap>
        <v-flex v-for="product in products" :key="`product-` + product.product_id" xs6 sm6 md3 lg3 class="pa-1 pa-md-2 pa-lg-2">
          <book-item :product="product" />
        </v-flex>
      </v-layout>
      <!-- <v-row align="center">
        <v-col
          cols="6"
          sm="6"
          md="3"
          v-for="product in products"
          :key="`product-` + product.product_id"
        >
          <book-item :product="product" />
        </v-col>
      </v-row> -->
    </v-container>

    <template>
      <div class="text-center">
        <v-pagination
          v-model="page"
          @input="go"
          :length="lengthPage"
          :total-visible="5"
        ></v-pagination>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "Products",
  components: {
    BookItem: () =>
      import(/* webpackChunkName: "book-item" */ "@/components/BookItem.vue"),
  },
  data: () => ({
    products: [],
    page: 1,
    lengthPage: 0,
  }),
  created() {
    this.go();
  },
  methods: {
    go() {
      this.$vloading.show();
      let url = "/all_products?page=" + this.page;
      this.axios
        .get(url)
        .then((response) => {
          this.$vloading.hide();
          let { data } = response.data;
          let { meta } = response.data;
          this.products = data;
          // console.log(meta);
          // jumlah halaman di dapat dari meta endpoint books
          this.lengthPage = meta.pageCount;
          this.page = meta.currentPage;
        })
        .catch((error) => {
          this.$vloading.hide();
          let { responses } = error;
          console.log(responses);
        });
    },
  },
};
</script>