<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left" v-if="user.data.role == 1">
        <v-btn
          small
          text
          to="/transactions"
          class="blue--text"
          v-if="route_id == ''"
        >
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
        <v-btn
          small
          text
          :to="'/transaction/' + route_id"
          class="blue--text"
          v-else
        >
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
      <div v-else>
        <v-btn small text to="/my-order" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <div id="print">
      <center>
        <table
          border="0"
          width="100%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td style="width: 20%" align="right">
              <img width="100px" src="/img/logo/logo-bon.png" />
            </td>
            <td align="center" style="line-height: 1em">
              <span style="font-size: 13px; line-height: 1.5em"
                ><b>KEMENTERIAN KEUANGAN REPUBLIK INDONESIA</b></span
              ><br />
              <span style="font-size: 11px; line-height: 1.5em">
                <b
                  >DIREKTORAT JENDERAL PAJAK<br />
                  KANTOR WILAYAH DIREKTORAT JENDERAL PAJAK SULAWESI UTARA,<br />
                  TENGAH, GORONTALO, DAN MALUKU UTARA</b
                ></span
              ><br />
              <span style="font-size: 7px">
                JALAN 17 AGUSTUS NO. 17 MANADO 95119<br />
                TELEPON (0431) 851785; 862742; FAKSIMILE (0431) 851803; LAMAN
                www.pajak.go.id<br />
                LAYANAN INFORMASI DAN PENGADUAN KRING PAJAK (021) 1500200<br />
                SUREL pengaduan@pajak.go.id; informasi@pajak.go.id
              </span>
            </td>
            <td style="width: 15%">&nbsp;</td>
          </tr>
        </table>
        <hr />
        <table
          border="0"
          width="95%"
          style="
            border-collapse: collapse;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 11px;
            margin-top: 2px;
          "
          align="center"
          cellpadding="5px"
        >
          <tr>
            <td align="center">
              <b>BON BARANG</b><br />
              NOMOR : {{ bill_number }}<br />
              <p align="justify">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Untuk menunjang tugas dan
                pekerjaan di {{ nama_bidang }} Kanwil DJP Sulawesi Utara,
                Tengah, Gorontalo, dan Maluku Utara, dengan ini kami sampaikan
                permohonan kebutuhan Alat Tulis Kantor sebagai berikut :
              </p>
              <br />
              <table
                border="1"
                width="100%"
                style="
                  border-collapse: collapse;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 11px;
                  margin-top: 2px;
                "
                align="center"
                cellpadding="5px"
              >
                <tr>
                  <th style="width: 5%">No.</th>
                  <th>Nama Barang</th>
                  <th>Jumlah</th>
                </tr>
                <tr v-for="dt in barang" :key="dt.id">
                  <td align="center">{{ barang.indexOf(dt) + 1 }}</td>
                  <td>
                    {{ dt.brand }} {{ dt.type }} {{ dt.product_name }} ({{
                      dt.product_code
                    }})
                  </td>
                  <td>{{ dt.qty }} {{ dt.unit }}</td>
                </tr>
              </table>
              <br />
              <table
                border="0"
                width="100%"
                style="
                  border-collapse: collapse;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 11px;
                  margin-top: 2px;
                "
                align="center"
                cellpadding="5px"
              >
                <tr>
                  <td style="width: 65%">&nbsp;</td>
                  <td>
                    Manado, {{ tgl_bon | moment("DD MMMM YYYY") }}<br />
                    {{ jabatan }},<br /><br />
                    <img
                      :src="ttd"
                      alt="TTD"
                      style="width: 150px; margin-left: -20px"
                    /><br />
                    {{ nama_pemesan }}
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </center>
    </div>
    <br />
    <center>
      <v-btn medium color="success" dark @click="makePDF">
        <v-icon left>mdi-printer</v-icon>
        Cetak
      </v-btn>
    </center>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
export default {
  name: "Bon",
  data: () => ({
    nomor: "",
    kode_bidang: "",
    nama_bidang: "",
    barang: [],
    tgl_bon: "",
    jabatan: "",
    nama_pemesan: "",
    ttd: "",
    route_id: localStorage.getItem("route_id"),
    transaction_number: localStorage.getItem("transaction_number"),
    bill_number: localStorage.getItem("bill_number"),
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getBon();
    },

    getBon() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/bill/" + this.transaction_number, config)
        .then((response) => {
          console.log(response.data[0]);
          var data = response.data[0];
          this.nomor = data.bill_number;
          this.kode_bidang = data.customer[0].division_code;
          this.nama_bidang = data.customer[0].division_name;
          this.barang = data.product;
          this.tgl_bon = data.date;
          this.jabatan = data.customer[0].position_name;
          this.nama_pemesan = data.customer[0].full_name;
          this.ttd = data.signature;
          //   this.datas = response.data;
          //   console.log(this.nomor);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    makePDF() {
      // window.html2canvas = html2canvas;
      // // var w = document.getElementById("print").offsetWidth;
      // // var h = document.getElementById("print").offsetHeight;
      // var doc = new jsPDF("p", "pt", "a4");
      // doc.html(document.getElementById("print"), {
      //   callback: function (pdf) {
      //     pdf.save("mypdf.pdf");
      //   },
      // });
      // var h = screen.height;
      // var w = screen.width;
      var prtContent = document.getElementById("print");
      var WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(function () {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
  },
};
</script>

<style scoped>
/* #print {
  font-family: "Arial";
  font-size: 11px;
  margin-top: 10px;
  margin-left: 15px;
  width: 100%;
} */
</style>