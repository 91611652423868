<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.brand="{ item }">
        {{ item.brand }} {{ item.type }} {{ item.product_name }} ({{
          item.product_code
        }})
      </template>
      <template v-slot:item.stock="{ item }">
        {{ item.stock }} {{ item.unit }}
      </template>
      <template v-slot:item.image="{ item }">
        <v-img :src="getImage(item.image)" width="100px" class="ma-1"></v-img
        ><br />
        <v-img :src="getImage(item.image2)" width="100px" class="ma-1"></v-img
        ><br />
        <v-img :src="getImage(item.image3)" width="100px" class="ma-1"></v-img>
      </template>
      <template v-slot:item.description="{ item }">
        <!-- <div v-readMore:50="item.description"></div> -->
        <read-more
          more-str="read more"
          :text="item.description"
          link="#"
          less-str="read less"
          :max-chars="50"
        ></read-more>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Produk</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="#FCEC27" class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.barcode"
                        :rules="rules"
                        label="Barcode"
                        type="number"
                        autofocus
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.category_id"
                        :items="kategori"
                        :rules="rules"
                        dense
                        item-value="id"
                        item-text="category"
                        label="Pilih Kategori"
                        v-on:change="kategoriChange"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.product_name_id"
                        :items="productname"
                        :rules="rules"
                        dense
                        item-value="product_name_id"
                        item-text="product_name"
                        label="Pilih Nama"
                        v-on:change="productnameChange"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.type_id"
                        :items="type"
                        :rules="rules"
                        dense
                        item-value="type_id"
                        item-text="type"
                        label="Pilih Tipe"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.brand"
                        :rules="rules"
                        label="Merk"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.unit_id"
                        :items="unit"
                        :rules="rules"
                        dense
                        item-value="id"
                        item-text="unit"
                        label="Pilih Satuan"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editedItem.description"
                        label="Deskripsi"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      Upload Gambar Pertama (max. 1 MB)
                      <input
                        type="file"
                        id="file"
                        ref="file"
                        accept="image/*"
                        v-on:change="handleFileUpload()"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      Upload Gambar Kedua (max. 1 MB)
                      <input
                        type="file"
                        id="file2"
                        ref="file2"
                        accept="image/*"
                        v-on:change="handleFileUpload2()"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      Upload Gambar Ketiga (max. 1 MB)
                      <input
                        type="file"
                        id="file3"
                        ref="file3"
                        accept="image/*"
                        v-on:change="handleFileUpload3()"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogImage" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="text-h5"
                  >Gambar {{ editedItem.brand }} {{ editedItem.type }}
                  {{ editedItem.product_name }} ({{
                    editedItem.product_code
                  }})</span
                >
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-carousel
                        height="450"
                        cycle
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item
                          :src="imgurl + editedItem.image"
                        ></v-carousel-item>
                        <v-carousel-item
                          :src="imgurl + editedItem.image2"
                        ></v-carousel-item>
                        <v-carousel-item
                          :src="imgurl + editedItem.image3"
                        ></v-carousel-item>
                      </v-carousel>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="imageItem(item)">
          mdi-image-multiple
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <!-- LOADER -->
    <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
      <v-card>
        <v-card-text>
          Sedang mengambil data...
          <v-progress-linear
            indeterminate
            color="primary"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    dialog: false,
    dialogImage: false,
    dialogLoading: false,
    dialogDelete: false,
    search: "",
    imgurl: process.env.VUE_APP_BACKEND_URL + "/assets/uploads/products/",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Barcode", value: "barcode" },
      {
        text: "Produk",
        align: "left",
        value: "brand",
      },
      { text: "Kategori", value: "category" },
      { text: "Stok", value: "stock" },
      { text: "Deskripsi", value: "description", width: "15%", sortable: false },
      // { text: "Gambar", value: "image" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    kategori: [],
    unit: [],
    productname: [],
    type: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Edit Data";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogImage(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getProduct();
      this.getKategori();
      this.getProductNameAll();
      this.getTypeAll();
      this.getUnit();
    },

    getProduct() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/product", config)
        .then((response) => {
          // console.log(response);
          this.datas = response.data.data;
          console.log(this.datas);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getUnit() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/unit", config)
        .then((response) => {
          // console.log(response);
          this.unit = response.data;
          this.$vloading.hide();
          // console.log(response.data);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getKategori() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/category", config)
        .then((response) => {
          // console.log(response);
          this.kategori = response.data;
          this.$vloading.hide();
          // console.log(response.data);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    kategoriChange: function (e) {
      // console.log(e);
      this.getProductName(e);
    },

    getProductName(item) {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/productname", config)
        .then((response) => {
          this.dialogLoading = false;

          var Data = response.data;
          var newData = Data.filter((e) => e.category_id == item);
          this.productname = newData;
          console.log(newData);
        })
        .catch((error) => {
          this.dialogLoading = false;
          // console.log(error.response.data.messages);
          this.productname = [];
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    productnameChange: function (e) {
      // console.log(e);
      this.getType(e);
    },

    getType(item) {
      this.dialogLoading = true;
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/type", config)
        .then((response) => {
          this.dialogLoading = false;

          var Data = response.data;
          var newData = Data.filter((e) => e.product_name_id == item);
          this.type = newData;
          // console.log(newData);
        })
        .catch((error) => {
          this.dialogLoading = false;
          // console.log(error.response.data.messages);
          this.type = [];
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getProductNameAll() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/productname", config)
        .then((response) => {
          // console.log(response);
          this.productname = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getTypeAll() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/type", config)
        .then((response) => {
          // console.log(response);
          this.type = response.data;
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    imageItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogImage = true;
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/product/" + this.editedItem.product_id, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getProduct();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.dialogImage = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set(
          "product_code",
          this.editedItem.category_id +
            this.editedItem.product_name_id +
            this.editedItem.type_id
        );
        formData.set("barcode", this.editedItem.barcode);
        formData.set("category_id", this.editedItem.category_id);
        formData.set("type_id", this.editedItem.type_id);
        formData.set("product_name_id", this.editedItem.product_name_id);
        formData.set("brand", this.editedItem.brand);
        formData.set("unit_id", this.editedItem.unit_id);
        formData.set("image", this.file);
        formData.set("image2", this.file2);
        formData.set("image3", this.file3);
        formData.set("description", this.editedItem.description);
        this.axios
          .post("/product/" + this.editedItem.product_id, formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Diubah!",
            });
            this.close();
            this.getProduct();
            this.getKategori();
            this.getProductNameAll();
            this.getTypeAll();
            this.getUnit();
            this.file = "";
            this.file2 = "";
            this.file3 = "";
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set(
          "product_code",
          this.editedItem.category_id +
            this.editedItem.product_name_id +
            this.editedItem.type_id
        );
        formData.set("barcode", this.editedItem.barcode);
        formData.set("category_id", this.editedItem.category_id);
        formData.set("type_id", this.editedItem.type_id);
        formData.set("product_name_id", this.editedItem.product_name_id);
        formData.set("brand", this.editedItem.brand);
        formData.set("unit_id", this.editedItem.unit_id);
        formData.set("image", this.file);
        formData.set("image2", this.file2);
        formData.set("image3", this.file3);
        formData.set("description", this.editedItem.description);
        this.axios
          .post("/product", formData, config)
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Data Berhasil Ditambahkan!",
            });
            this.close();
            this.getProduct();
            this.getKategori();
            this.getProductNameAll();
            this.getTypeAll();
            this.getUnit();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      }
      this.close();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },

    handleFileUpload2() {
      this.file2 = this.$refs.file2.files[0];
    },

    handleFileUpload3() {
      this.file3 = this.$refs.file3.files[0];
    },
  },
};
</script>