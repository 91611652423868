<template>
  <div>
    <h1 class="subheading grey--text">Dashboard</h1>
    <v-row>
      <v-col cols="12" sm="4" md="2">
        <v-card class="mx-auto text-left" color="info" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countSemuaTransaksi }}
            </div>
            <div style="color: white">Semua Transaksi</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/transactions">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-card class="mx-auto text-left" color="grey darken-1" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countBelumDiproses }}
            </div>
            <div style="color: white">Belum Diproses</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/transaction/1">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-card class="mx-auto text-left" color="yellow darken-3" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countSementaraDiproses }}
            </div>
            <div style="color: white">Sementara Diproses</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/transaction/2">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-card class="mx-auto text-left" color="orange darken-3" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countProsesPengantaran }}
            </div>
            <div style="color: white">Proses Pengantaran</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/transaction/3">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-card class="mx-auto text-left" color="green" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countSelesai }}
            </div>
            <div style="color: white">Selesai</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/transaction/4">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" md="2">
        <v-card class="mx-auto text-left" color="red" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countDibatalkan }}
            </div>
            <div style="color: white">Dibatalkan</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/transaction/5">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <v-card class="mx-auto text-left" color="teal" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countTransHariIni }}
            </div>
            <div class="text-h6 font-weight-light" style="color: white">
              Total Transaksi Hari Ini
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/transactions">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <v-card class="mx-auto text-left" color="purple" dark>
          <v-card-text>
            <div class="text-h4 font-weight-medium" style="color: white">
              {{ countStokMasukHariIni }}
            </div>
            <div class="text-h6 font-weight-light" style="color: white">
              Total Stok Masuk Hari Ini
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-center ma-0 pa-0">
            <v-btn block text to="/stok-masuk">
              Lihat <v-icon right>mdi-arrow-right-circle</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <br />
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.brand="{ item }">
        {{ item.brand }} {{ item.type }} {{ item.product_name }} ({{
          item.product_code
        }})
      </template>
      <template v-slot:item.stock="{ item }">
        {{ item.stock }} {{ item.unit }}
      </template>
      <template v-slot:top>
        <v-toolbar dark color="primary" class="pt-2">
          <v-toolbar-title class="pt-3"
            >Daftar Produk
            <span class="ml-3" style="float: right">
              <v-select
                v-model="stok"
                :items="itemsStok"
                label="Minimal Stok"
                dense
                outlined
                v-on:change="stokChange"
              ></v-select>
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-dialog v-model="dialog" max-width="600px">
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Anda yakin ingin menghapus data ini?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Batal</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.actions="{}">
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table> -->
    <br />
    <h3>Total Transaksi Per Bidang</h3>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              v-model="bulan"
              :items="bulanItems"
              item-value="id"
              item-text="name"
              label="Dari Bulan"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
              v-model="bulanSampai"
              :items="bulanItems"
              item-value="id"
              item-text="name"
              label="Sampai Bulan"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="tahun"
              label="Tahun"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-btn color="#FCEC27" @click="getChartDivision">
              <v-icon left dark>mdi-text-search</v-icon>
              Tampilkan
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptionsDivision"
      :series="seriesDivision"
    ></apexchart>
    <br />
    <h3>Total Barang Per Bidang</h3>
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              v-model="bulanBarang"
              :items="bulanItems"
              item-value="id"
              item-text="name"
              label="Dari Bulan"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
              v-model="bulanSampaiBarang"
              :items="bulanItems"
              item-value="id"
              item-text="name"
              label="Sampai Bulan"
              dense
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              v-model="tahunBarang"
              label="Tahun"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-btn color="#FCEC27" @click="getChartItems">
              <v-icon left dark>mdi-text-search</v-icon>
              Tampilkan
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptionsItems"
      :series="seriesItems"
    ></apexchart>
    <br />
    <h3>Statistik Transaksi Per Bulan</h3>
    <apexchart
      type="line"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    countSemuaTransaksi: "",
    countBelumDiproses: "",
    countSementaraDiproses: "",
    countProsesPengantaran: "",
    countSelesai: "",
    countDibatalkan: "",
    countTransHariIni: "",
    countStokMasukHariIni: "",
    stok: 5,
    bulan: 1,
    bulanBarang: 1,
    bulanSampai: new Date().getMonth() + 1,
    bulanSampaiBarang: new Date().getMonth() + 1,
    tahun: new Date().getFullYear(),
    tahunBarang: new Date().getFullYear(),
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      { text: "No", value: "nomor" },
      { text: "Barcode", value: "barcode" },
      {
        text: "Produk",
        align: "left",
        value: "brand",
      },
      { text: "Kategori", value: "category" },
      { text: "Stok", value: "stock" },
      //   { text: "Deskripsi", value: "description", width: "15%" },
      //   { text: "Gambar", value: "image" },
      //   { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    kategori: [],
    unit: [],
    productname: [],
    type: [],
    timer: "",
    statusItems: [
      { id: "1", status: "Belum Diproses" },
      { id: "2", status: "Sementara Diproses" },
      { id: "3", status: "Proses Pengantaran" },
      { id: "4", status: "Selesai" },
      { id: "5", status: "Dibatalkan" },
    ],
    itemsStok: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    bulanItems: [
      { id: 1, name: "Januari" },
      { id: 2, name: "Februari" },
      { id: 3, name: "Maret" },
      { id: 4, name: "April" },
      { id: 5, name: "Mei" },
      { id: 6, name: "Juni" },
      { id: 7, name: "Juli" },
      { id: 8, name: "Agustus" },
      { id: 9, name: "September" },
      { id: 10, name: "Oktober" },
      { id: 11, name: "November" },
      { id: 12, name: "Desember" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],

    series: [],
    chartOptions: {},

    seriesItems: [],
    chartOptionsItems: {},

    seriesDivision: [],
    chartOptionsDivision: {},
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Ubah Status Transaksi";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
    this.timer = setInterval(this.initialize, 300000);
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set",
      setAuth: "auth/set", // <=
    }),
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },

    initialize() {
      this.getCountTransactions();
      this.getCountTransactionToday();
      this.getCountStockToday();
      this.getCountTransactionStatusOne();
      this.getCountTransactionStatusTwo();
      this.getCountTransactionStatusThree();
      this.getCountTransactionStatusFour();
      this.getCountTransactionStatusFive();
      this.getProduct(this.stok);
      this.getChartDivision();
      this.getChartItems();
      this.getChart();
    },

    getCountTransactions() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction", config)
        .then((response) => {
          // console.log(response);
          this.countSemuaTransaksi = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    getCountTransactionToday() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      //   console.log(date);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction/date/" + date, config)
        .then((response) => {
          // console.log(response);
          this.countTransHariIni = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getCountStockToday() {
      var today = new Date();
      var date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();
      //   console.log(date);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/stock_history/date/" + date, config)
        .then((response) => {
          // console.log(response);
          this.countStokMasukHariIni = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getCountTransactionStatusOne() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction/status/1", config)
        .then((response) => {
          // console.log(response);
          this.countBelumDiproses = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getCountTransactionStatusTwo() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction/status/2", config)
        .then((response) => {
          // console.log(response);
          this.countSementaraDiproses = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getCountTransactionStatusThree() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction/status/3", config)
        .then((response) => {
          // console.log(response);
          this.countProsesPengantaran = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getCountTransactionStatusFour() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction/status/4", config)
        .then((response) => {
          // console.log(response);
          this.countSelesai = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getCountTransactionStatusFive() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction/status/5", config)
        .then((response) => {
          // console.log(response);
          this.countDibatalkan = response.data.count;
          //   console.log(this.countSemuaTransaksi);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    stokChange: function (e) {
      //   console.log(e);
      this.getProduct(e);
    },

    getProduct(stok) {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/product/min/" + stok, config)
        .then((response) => {
          // console.log(response);
          this.datas = response.data;
          console.log(this.datas);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getChart() {
      var today = new Date();
      var year = today.getFullYear();
      // console.log(year);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/dashboard/transaction/year/" + year + "/chart", config)
        .then((response) => {
          // console.log(response.data);
          var data = response.data;
          const COUNT = data.map((key) => {
            return parseInt(key.count);
          });
          //   this.datas = response.data;
          //   console.log(this.datas);
          //   this.$vloading.hide();

          this.series = [
            {
              name: "Total Transaksi",
              data: COUNT,
            },
          ];

          this.chartOptions = {
            chart: {
              height: 350,
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: "straight",
            },
            // title: {
            //   text: "Statistik Transaksi Per Bulan",
            //   align: "left",
            // },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "Mei",
                "Jun",
                "Jul",
                "Ags",
                "Sep",
                "Okt",
                "Nov",
                "Des",
              ],
            },
          };

          // console.log(this.chartOptions);
          // console.log(this.series);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getChartDivision() {
      // var today = new Date();
      // var year = today.getFullYear();
      // console.log(year);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get(
          "/dashboard/transaction/division/fromMonth/" +
            this.bulan +
            "/toMonth/" +
            this.bulanSampai +
            "/year/" +
            this.tahun +
            "/chart",
          config
        )
        .then((response) => {
          console.log(response.data);
          var data = response.data;
          const COUNT = data.map((key) => {
            return parseInt(key.count);
          });
          const NAME = data.map((key) => {
            return key.division;
          });
          //   this.datas = response.data;
          //   console.log(this.datas);
          this.$vloading.hide();

          this.seriesDivision = [
            {
              name: "Total Transaksi",
              data: COUNT,
            },
          ];

          this.chartOptionsDivision = {
            chart: {
              height: 350,
              type: "bar",
            },
            plotOptions: {
              bar: {
                columnWidth: "45%",
                distributed: true,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: false,
            },
            // title: {
            //   text: "Total Transaksi Per Bidang",
            //   align: "left",
            // },
            xaxis: {
              categories: NAME,
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          };

          // console.log(this.chartOptions);
          // console.log(this.series);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getChartItems() {
      // var today = new Date();
      // var year = today.getFullYear();
      // console.log(year);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get(
          "/dashboard/transaction/items/fromMonth/" +
            this.bulanBarang +
            "/toMonth/" +
            this.bulanSampaiBarang +
            "/year/" +
            this.tahunBarang +
            "/chart",
          config
        )
        .then((response) => {
          console.log(response.data);
          var data = response.data;
          const COUNT = data.map((key) => {
            return parseInt(key.items);
          });
          const NAME = data.map((key) => {
            return key.division;
          });
          //   this.datas = response.data;
          //   console.log(this.datas);
          this.$vloading.hide();

          this.seriesItems = [
            {
              name: "Total Barang",
              data: COUNT,
            },
          ];

          this.chartOptionsItems = {
            chart: {
              height: 350,
              type: "bar",
            },
            plotOptions: {
              bar: {
                columnWidth: "45%",
                distributed: true,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: false,
            },
            // title: {
            //   text: "Total Barang Per Bidang",
            //   align: "left",
            // },
            xaxis: {
              categories: NAME,
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          };

          // console.log(this.chartOptions);
          // console.log(this.series);
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/category/" + this.editedItem.id, config)
        .then(() => {
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getTransactions();
          this.closeDelete();
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("user_id", this.user.data.user_id);
        formData.set("status", this.editedItem.status);
        formData.set("transaction_number", this.editedItem.transaction_number);
        this.axios
          .post(
            "/transaction/" + this.editedItem.transaction_id,
            formData,
            config
          )
          .then(() => {
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Status Transaksi Berhasil Diubah!",
            });
            this.close();
            this.getTransactions();
          })
          .catch((error) => {
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
      }
      this.close();
    },

    detail(item) {
      // console.log(item.transaction_number);
      // console.log(item.customer[0].full_name);
      localStorage.route_id = "";
      localStorage.transaction_id = item.transaction_id;
      localStorage.transaction_number = item.transaction_number;
      localStorage.transaction_date = item.transaction_date;
      // localStorage.status = item.status;
      localStorage.nip = item.customer[0].nip;
      localStorage.full_name = item.customer[0].full_name;
      localStorage.phone = item.customer[0].phone;
      localStorage.email = item.customer[0].email;
      localStorage.position_id = item.customer[0].position_name;
      localStorage.division_id = item.customer[0].division_name;
      localStorage.section_id = item.customer[0].section_name;
      localStorage.grade = item.customer[0].grade;
      localStorage.product = item.total.product;
      localStorage.qty = item.total.qty;
      this.$router.replace({ name: "Transaction Detail" });
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>