<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="datas"
      sort-by="calories"
      class="elevation-1"
      :search="search"
    >
      <template v-slot:item.nomor="{ item }">
        {{ datas.indexOf(item) + 1 }}
      </template>
      <template v-slot:item.transaction_date="{ item }">
        {{ item.transaction_date | moment("DD MMMM YYYY, HH:mm") }} WITA
      </template>
      <template v-slot:item.pesanan="{ item }">
        {{ item.total.product }} Produk ({{ item.total.qty }} Items)
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status == 1">
          <v-chip small rounded color="grey" dark> Belum Diproses </v-chip>
        </span>
        <span v-if="item.status == 2">
          <v-chip small rounded color="yellow darken-2" dark>
            Sementara Diproses
          </v-chip>
        </span>
        <span v-if="item.status == 3">
          <v-chip small rounded color="orange darken-3" dark>
            Proses Pengantaran
          </v-chip>
        </span>
        <span v-if="item.status == 4">
          <v-chip small rounded color="green" dark> Selesai </v-chip>
        </span>
        <span v-if="item.status == 5">
          <v-chip small rounded color="red" dark> Dibatalkan </v-chip>
        </span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Transaksi</v-toolbar-title>
          </v-toolbar>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn color="#FCEC27" class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template> -->
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedItem.bill_number"
                        :rules="rules"
                        label="Nomor"
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedItem.status"
                        :items="statusAdminItems"
                        :rules="rules"
                        dense
                        item-value="id"
                        item-text="status"
                        label="Pilih Status"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Anda yakin ingin menghapus data ini?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Batal</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-text-field v-model="search" label="Cari" class="mx-4"></v-text-field>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
        <v-btn x-small class="mr-1" color="primary" dark @click="detail(item)">
          <v-icon x-small>mdi-eye</v-icon>&nbsp;Detail
        </v-btn>
        <v-btn
          x-small
          color="info"
          @click="editItem(item)"
          v-if="item.status < 3"
        >
          <v-icon x-small>mdi-circle-edit-outline </v-icon>
          &nbsp;Status
        </v-btn>
        <!-- <v-btn
        x-small
        color="success"
        @click="modalSelesai"
        v-if="item.status == 3"
      >
        <v-icon x-small> mdi-circle-edit-outline </v-icon>
        &nbsp;Selesai
      </v-btn> -->
        <v-menu offset-y v-if="item.status == 3">
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small color="info" v-bind="attrs" v-on="on">
              <v-icon x-small>mdi-circle-edit-outline </v-icon>
              &nbsp;Status
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="editItem(item)" link>
              <v-list-item-title>Ubah</v-list-item-title>
            </v-list-item>
            <v-list-item @click="modalSelesai(item)" link>
              <v-list-item-title>Selesai</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          x-small
          color="#FCEC27"
          @click="cetakBon(item)"
          v-if="item.status == 4"
        >
          <v-icon x-small>mdi-printer </v-icon>
          &nbsp;Cetak Bon
        </v-btn>
      </template>
      <template v-slot:no-data> Tidak ada data </template>
    </v-data-table>

    <v-dialog v-model="dialogSelesai" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Konfirmasi Pesanan Selesai</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="editedItem.bill_number"
                  :rules="rules"
                  label="Nomor"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Tanda Tangan Dibawah Ini
                <vueSignature
                  ref="signature"
                  :sigOption="option"
                ></vueSignature>
                <v-btn small class="mt-2 mr-2" @click="clear"> Clear </v-btn>
                <v-btn small class="mt-2" @click="undo"> Undo </v-btn>
                <!-- <button @click="saveTTD">Save</button>
                <button @click="clear">Clear</button>
                <button @click="undo">Undo</button> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeSelesai">
            Batal
          </v-btn>
          <v-btn color="blue darken-1" text @click="saveSelesai">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vueSignature from "vue-signature";
export default {
  components: {
    vueSignature,
  },
  data: () => ({
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
    },
    dialog: false,
    dialogDelete: false,
    dialogSelesai: false,
    search: "",
    headers: [
      { text: "No", value: "nomor", width: "5%" },
      {
        text: "Nomor",
        align: "left",
        value: "bill_number",
        // width: "22%",
      },
      { text: "Tanggal Transaksi", value: "transaction_date" },
      { text: "Customer", value: "customer[0].full_name" },
      { text: "Pesanan", value: "pesanan" },
      { text: "Bidang", value: "customer[0].division_name" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    datas: [],
    statusItems: [
      { id: "1", status: "Belum Diproses" },
      { id: "2", status: "Sementara Diproses" },
      { id: "3", status: "Proses Pengantaran" },
      { id: "4", status: "Selesai" },
      { id: "5", status: "Dibatalkan" },
    ],
    statusAdminItems: [
      { id: "1", status: "Belum Diproses" },
      { id: "2", status: "Sementara Diproses" },
      { id: "3", status: "Proses Pengantaran" },
      { id: "5", status: "Dibatalkan" },
    ],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tambah Data" : "Ubah Status Transaksi";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
      setAuth: "auth/set", // <=
    }),

    initialize() {
      this.getTransactions();
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },

    getTransactions() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/transaction", config)
        .then((response) => {
          // console.log(response);
          this.datas = response.data;
          console.log(this.datas);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          console.log(error.response.status);
          if (error.response.status == 401) {
            this.setAuth({}); // kosongkan auth ketika logout
            // this.setAlert({
            //   status: true,
            //   color: "error",
            //   text: "Login First!",
            // });
            this.$router.replace({ name: "Home" });
          } else {
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          }
        });
    },

    editItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    modalSelesai(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogSelesai = true;
    },

    deleteItem(item) {
      this.editedIndex = this.datas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      //   DELETE DATA
      //   this.datas.splice(this.editedIndex, 1);
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .delete("/category/" + this.editedItem.id, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Data Berhasil Dihapus!",
          });
          this.close();
          this.getTransactions();
          this.closeDelete();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
          this.closeDelete();
        });
    },

    close() {
      this.dialog = false;
      this.dialogSelesai = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeSelesai() {
      this.dialogSelesai = false;
      var _this = this;
      _this.$refs.signature.clear();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        // EDIT DATA
        // Object.assign(this.datas[this.editedIndex], this.editedItem);
        this.$vloading.show();
        let config = {
          headers: {
            Authorization: "Bearer " + this.user.data.access_token,
          },
        };
        let formData = new FormData();
        formData.set("user_id", this.user.data.user_id);
        formData.set("status", this.editedItem.status);
        formData.set("transaction_number", this.editedItem.transaction_number);
        this.axios
          .post(
            "/transaction/" + this.editedItem.transaction_id,
            formData,
            config
          )
          .then(() => {
            this.$vloading.hide();
            // console.log(response);
            this.setAlert({
              status: true,
              color: "success",
              text: "Status Transaksi Berhasil Diubah!",
            });
            this.close();
            this.getTransactions();
          })
          .catch((error) => {
            this.$vloading.hide();
            // console.log(error.response.data.messages);
            this.setAlert({
              status: true,
              color: "error",
              text: error.response.data.messages,
            });
            this.close();
          });
      } else {
        // INSERT DATA
        // this.datas.push(this.editedItem);
      }
      this.close();
    },

    saveSelesai() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("user_id", this.user.data.user_id);
      formData.set("status", "4");
      formData.set("transaction_number", this.editedItem.transaction_number);
      this.axios
        .post(
          "/transaction/" + this.editedItem.transaction_id,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Status Transaksi Berhasil Diubah!",
          });
          this.close();
          this.getTransactions();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });

      var _this = this;
      var png = _this.$refs.signature.save();
      let formDataBill = new FormData();
      formDataBill.set("bill_number", this.editedItem.transaction_id);
      formDataBill.set(
        "transaction_number",
        this.editedItem.transaction_number
      );
      formDataBill.set("user_id", this.editedItem.customer[0].user_id);
      formDataBill.set("signature", png);
      this.axios
        .post("/bill", formDataBill, config)
        .then(() => {
          // this.$vloading.hide();
          // // console.log(response);
          // this.setAlert({
          //   status: true,
          //   color: "success",
          //   text: "Status Transaksi Berhasil Diubah!",
          // });
          // this.close();
          // this.getDetailTransactions();
          // this.getLogsTransactions();
          // this.getDetailTransactionsByID();
        })
        .catch((error) => {
          // this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    detail(item) {
      // console.log(item.transaction_number);
      // console.log(item.customer[0].full_name);
      localStorage.route_id = "";
      localStorage.transaction_id = item.transaction_id;
      localStorage.transaction_number = item.transaction_number;
      localStorage.bill_number = item.bill_number;
      localStorage.transaction_date = item.transaction_date;
      // localStorage.status = item.status;
      localStorage.nip = item.customer[0].nip;
      localStorage.full_name = item.customer[0].full_name;
      localStorage.phone = item.customer[0].phone;
      localStorage.email = item.customer[0].email;
      localStorage.position_id = item.customer[0].position_name;
      localStorage.division_id = item.customer[0].division_name;
      localStorage.section_id = item.customer[0].section_name;
      localStorage.grade = item.customer[0].grade;
      localStorage.product = item.total.product;
      localStorage.qty = item.total.qty;
      this.$router.replace({ name: "Transaction Detail" });
    },

    cetakBon(item) {
      // console.log(item.transaction_number);
      // console.log(item.customer[0].full_name);
      localStorage.route_id = "";
      localStorage.transaction_id = item.transaction_id;
      localStorage.transaction_number = item.transaction_number;
      localStorage.bill_number = item.bill_number;
      localStorage.transaction_date = item.transaction_date;
      // localStorage.status = item.status;
      localStorage.nip = item.customer[0].nip;
      localStorage.full_name = item.customer[0].full_name;
      localStorage.phone = item.customer[0].phone;
      localStorage.email = item.customer[0].email;
      localStorage.position_id = item.customer[0].position_name;
      localStorage.division_id = item.customer[0].division_name;
      localStorage.section_id = item.customer[0].section_name;
      localStorage.grade = item.customer[0].grade;
      localStorage.product = item.total.product;
      localStorage.qty = item.total.qty;
      this.$router.replace({ name: "Bon" });
    },
  },
};
</script>