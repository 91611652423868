<template>
  <div>
    <v-container class="ma-0 pa-1" grid-list-sm>
      <div class="text-left" v-if="user.data.role == 1">
        <v-btn
          small
          text
          to="/transactions"
          class="blue--text"
          v-if="route_id == ''"
        >
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
        <v-btn
          small
          text
          :to="'/transaction/' + route_id"
          class="blue--text"
          v-else
        >
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
      <div v-else>
        <v-btn small text to="/my-order" class="blue--text">
          <v-icon>mdi-chevron-left</v-icon> Kembali
        </v-btn>
      </div>
    </v-container>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-stepper :value="logs.length + 1">
          <v-stepper-header>
            <span v-for="dt in stepItems" :key="dt.id">
              <span v-for="log in logs" :key="log.id">
                <span v-if="log.status == dt.id">
                  <v-stepper-step :step="dt.id" complete color="success">
                    {{ dt.status }}
                    <small>{{ log.datetime }}</small>
                  </v-stepper-step>
                </span>
              </span>
              <!-- <v-divider></v-divider> -->
              <v-stepper-step :step="dt.id">
                {{ dt.status }}
              </v-stepper-step>
            </span>
            <!-- <span v-for="dt in logs" :key="dt.id">
              <v-stepper-step :step="dt.status" complete color="success">
                <span v-if="dt.status == 1"> Belum Diproses </span>
                <span v-if="dt.status == 2"> Sementara Diproses </span>
                <span v-if="dt.status == 3"> Proses Pengantaran </span>
                <span v-if="dt.status == 4"> Selesai </span>
                <span v-if="dt.status == 5"> Dibatalkan </span>
                <small>{{ dt.datetime }}</small></v-stepper-step
              >
              <v-divider></v-divider>
            </span> -->
            <!-- <v-stepper-step step="2" complete color="success">
              Sementara Diproses
              <small>12 Juli 2021, 12:52 WITA</small></v-stepper-step
            >
            <v-divider></v-divider>
            <v-stepper-step step="3"> Proses Pengantaran </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4"> Selesai </v-stepper-step> -->
          </v-stepper-header>
        </v-stepper>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-expansion-panels multiple :value="panel">
          <v-expansion-panel>
            <v-expansion-panel-header dark color="primary" style="color: white">
              Detail Informasi
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-simple-table class="pa-2" dense>
                <thead>
                  <tr>
                    <td colspan="2">
                      <strong><u>Informasi Transaksi</u></strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nomor</td>
                    <td>{{ bill_number }}</td>
                  </tr>
                  <tr>
                    <td>Tanggal Transaksi</td>
                    <td>
                      {{ transaction_date | moment("DD MMMM YYYY, HH:mm") }}
                      WITA
                    </td>
                  </tr>
                  <tr>
                    <td>Status Transaksi</td>
                    <td>
                      <span v-if="status == 1">
                        <v-chip small rounded color="grey" dark>
                          Belum Diproses
                        </v-chip>
                      </span>
                      <span v-if="status == 2">
                        <v-chip small rounded color="yellow darken-2" dark>
                          Sementara Diproses
                        </v-chip>
                      </span>
                      <span v-if="status == 3">
                        <v-chip small rounded color="orange darken-3" dark>
                          Proses Pengantaran
                        </v-chip>
                      </span>
                      <span v-if="status == 4">
                        <v-chip small rounded color="green" dark>
                          Selesai
                        </v-chip>
                      </span>
                      <span v-if="status == 5">
                        <v-chip small rounded color="red" dark>
                          Dibatalkan
                        </v-chip>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
              <v-divider></v-divider>
              <v-simple-table class="pa-2" dense>
                <thead>
                  <tr>
                    <td colspan="2">
                      <strong><u>Informasi Customer</u></strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nama</td>
                    <td>{{ full_name }}</td>
                  </tr>
                  <tr>
                    <td>NIP</td>
                    <td>{{ nip }}</td>
                  </tr>
                  <tr>
                    <td>No. HP</td>
                    <td>{{ phone }}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{{ email }}</td>
                  </tr>
                  <tr>
                    <td>Jabatan</td>
                    <td>{{ position_id }}</td>
                  </tr>
                  <tr>
                    <td>Bidang</td>
                    <td>{{ division_id }}</td>
                  </tr>
                  <tr>
                    <td>Seksi</td>
                    <td>{{ section_id }}</td>
                  </tr>
                  <tr>
                    <td>Pangkat/Golongan</td>
                    <td>{{ grade }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-expansion-panels multiple :value="panel2">
          <v-expansion-panel>
            <v-expansion-panel-header dark color="primary" style="color: white">
              Detail Pesanan
              <template v-slot:actions>
                <v-icon color="white"> mdi-chevron-down </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="text-right mt-2" v-if="user.data.role == 1">
                <v-btn
                  color="warning"
                  small
                  @click="modalEdit"
                  v-if="status < 4"
                >
                  <v-icon left> mdi-circle-edit-outline </v-icon>
                  Ubah Jumlah
                </v-btn>
              </div>
              <v-list three-line>
                <template>
                  <v-list-item
                    v-for="(item, index) in datas"
                    :key="'data' + index"
                  >
                    <v-list-item-avatar>
                      <v-img :src="getImage(item.image)"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="
                          '<b>' +
                            item.brand +
                            ' ' +
                            item.type +
                            ' ' +
                            item.product_name +
                            ' (' +
                            item.product_code +
                            ')</b>'
                        "
                      ></v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.category }}
                        <span style="float: right">
                          <b>{{ item.quantity }} {{ item.unit }}</b>
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
              <v-divider></v-divider>
              <v-layout wrap class="pt-2">
                <v-flex pa-1 xs6>
                  <span class="title">Total {{ qty_total }} Items</span>
                </v-flex>
                <v-flex pa-1 xs6 text-right>
                  <span v-if="user.data.role == 1">
                    <v-btn color="info" @click="modalStatus" v-if="status < 3">
                      <v-icon left> mdi-circle-edit-outline </v-icon>
                      Status
                    </v-btn>
                  </span>

                  <span v-if="user.data.user_id == customerID">
                    <v-btn
                      color="success"
                      @click="modalSelesai"
                      v-if="status == 3"
                    >
                      <v-icon left> mdi-circle-edit-outline </v-icon>
                      Selesai
                    </v-btn>
                    <v-btn color="#FCEC27" @click="cetakBon" v-if="status == 4">
                      <v-icon left> mdi-printer </v-icon>
                      Cetak Bon
                    </v-btn>
                  </span>
                </v-flex>
              </v-layout>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Ubah Status Transaksi</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="bill_number"
                  :rules="rules"
                  label="Nomor"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="statusField"
                  :items="statusAdminItems"
                  :rules="rules"
                  dense
                  item-value="id"
                  item-text="status"
                  label="Pilih Status"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSelesai" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Konfirmasi Pesanan Selesai</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="bill_number"
                  :rules="rules"
                  label="Nomor"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                Tanda Tangan Dibawah Ini
                <vueSignature
                  ref="signature"
                  :sigOption="option"
                ></vueSignature>
                <v-btn small class="mt-2 mr-2" @click="clear"> Clear </v-btn>
                <v-btn small class="mt-2" @click="undo"> Undo </v-btn>
                <!-- <button @click="saveTTD">Save</button>
                <button @click="clear">Clear</button>
                <button @click="undo">Undo</button> -->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="mt-6">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
          <v-btn color="blue darken-1" text @click="saveSelesai">
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEdit" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Ubah Jumlah Item Pesanan</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-list three-line>
                  <template>
                    <v-list-item
                      v-for="(item, index) in datas"
                      :key="'data' + index"
                    >
                      <v-list-item-avatar>
                        <v-img :src="getImage(item.image)"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            '<b>' +
                              item.brand +
                              ' ' +
                              item.type +
                              ' ' +
                              item.product_name +
                              ' (' +
                              item.product_code +
                              ')</b>'
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle>
                          {{ item.category
                          }}<v-text-field
                            min="0"
                            v-model.number="item.quantity"
                            type="number"
                            dense
                            outlined
                            :hint="item.unit"
                            persistent-hint
                          >
                          </v-text-field>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> Batal </v-btn>
          <v-btn color="blue darken-1" text @click="saveEdit"> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vueSignature from "vue-signature";

export default {
  name: "AdminDetailTransaction",
  components: {
    vueSignature,
  },
  data: () => ({
    option: {
      penColor: "rgb(0, 0, 0)",
      backgroundColor: "rgb(255,255,255)",
    },
    dialog: false,
    dialogSelesai: false,
    dialogEdit: false,
    panel: [0],
    panel2: [0],
    datas: [],
    logs: [],
    stepItems: [
      { id: "1", status: "Belum Diproses" },
      { id: "2", status: "Sementara Diproses" },
      { id: "3", status: "Proses Pengantaran" },
      { id: "4", status: "Selesai" },
    ],
    statusItems: [
      { id: "1", status: "Belum Diproses" },
      { id: "2", status: "Sementara Diproses" },
      { id: "3", status: "Proses Pengantaran" },
      { id: "4", status: "Selesai" },
      { id: "5", status: "Dibatalkan" },
    ],
    statusAdminItems: [
      { id: "1", status: "Belum Diproses" },
      { id: "2", status: "Sementara Diproses" },
      { id: "3", status: "Proses Pengantaran" },
      { id: "5", status: "Dibatalkan" },
    ],
    route_id: localStorage.getItem("route_id"),
    transaction_id: localStorage.getItem("transaction_id"),
    transaction_number: localStorage.getItem("transaction_number"),
    bill_number: localStorage.getItem("bill_number"),
    transaction_date: localStorage.getItem("transaction_date"),
    status: "",
    customerID: "",
    statusField: "",
    nip: localStorage.getItem("nip"),
    full_name: localStorage.getItem("full_name"),
    phone: localStorage.getItem("phone"),
    email: localStorage.getItem("email"),
    position_id: localStorage.getItem("position_id"),
    division_id: localStorage.getItem("division_id"),
    section_id: localStorage.getItem("section_id"),
    grade: localStorage.getItem("grade"),
    product: localStorage.getItem("product"),
    qty_total: 0,
    rules: [(v) => !!v || "Form ini wajib di isi"],
  }),

  computed: {
    ...mapGetters({
      // carts: "cart/carts",
      // countCart: "cart/count",
      // totalPrice: "cart/totalPrice",
      // totalQuantity: "cart/totalQuantity",
      // totalWeight: "cart/totalWeight",
      user: "auth/user",
    }),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions({
      setAlert: "alert/set", // <=
    }),

    initialize() {
      this.getDetailTransactions();
      this.getLogsTransactions();
      this.getDetailTransactionsByID();
    },

    saveTTD() {
      var _this = this;
      var png = _this.$refs.signature.save();
      var jpeg = _this.$refs.signature.save("image/jpeg");
      var svg = _this.$refs.signature.save("image/svg+xml");
      console.log(png);
      console.log(jpeg);
      console.log(svg);
    },
    clear() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    undo() {
      var _this = this;
      _this.$refs.signature.undo();
    },

    getDetailTransactionsByID() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/transaction/detail/" + this.transaction_id, config)
        .then((response) => {
          console.log(response.data);
          this.customerID = response.data[0].customer[0].user_id;
          this.status = response.data[0].status;
          this.statusField = response.data[0].status;
          // console.log(this.status);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getDetailTransactions() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/transaction/" + this.transaction_number, config)
        .then((response) => {
          // console.log(response);
          this.datas = response.data;
          console.log("item ", this.datas);

          // hitung qty dari datas qty
          this.qty_total = this.datas.reduce((acc, item) => {
            return acc + +item.quantity;
          }, 0);
          this.$vloading.hide();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    getLogsTransactions() {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      this.axios
        .get("/transaction/" + this.transaction_number + "/logs", config)
        .then((response) => {
          // console.log(response);
          // var log = response.data;
          this.logs = response.data;
          // console.log(response.data);
          // console.log(this.logs);
          // console.log(last);
        })
        .catch((error) => {
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    modalStatus() {
      // this.editedIndex = this.datas.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    modalSelesai() {
      // this.editedIndex = this.datas.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.dialogSelesai = true;
    },

    modalEdit() {
      this.dialogEdit = true;
    },

    save() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("user_id", this.user.data.user_id);
      formData.set("status", this.statusField);
      formData.set("transaction_number", this.transaction_number);
      this.axios
        .post("/transaction/" + this.transaction_id, formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Status Transaksi Berhasil Diubah!",
          });
          this.close();
          this.getDetailTransactions();
          this.getLogsTransactions();
          this.getDetailTransactionsByID();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    saveSelesai() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("user_id", this.user.data.user_id);
      formData.set("status", "4");
      formData.set("transaction_number", this.transaction_number);
      this.axios
        .post("/transaction/" + this.transaction_id, formData, config)
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Status Transaksi Berhasil Diubah!",
          });
          this.close();
          this.getDetailTransactions();
          this.getLogsTransactions();
          this.getDetailTransactionsByID();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });

      var _this = this;
      var png = _this.$refs.signature.save();
      let formDataBill = new FormData();
      formDataBill.set("bill_number", this.transaction_id);
      formDataBill.set("transaction_number", this.transaction_number);
      formDataBill.set("user_id", this.user.data.user_id);
      formDataBill.set("signature", png);
      this.axios
        .post("/bill", formDataBill, config)
        .then(() => {
          // this.$vloading.hide();
          // // console.log(response);
          // this.setAlert({
          //   status: true,
          //   color: "success",
          //   text: "Status Transaksi Berhasil Diubah!",
          // });
          // this.close();
          // this.getDetailTransactions();
          // this.getLogsTransactions();
          // this.getDetailTransactionsByID();
        })
        .catch((error) => {
          // this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    saveEdit() {
      this.$vloading.show();
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.data.access_token,
        },
      };
      let formData = new FormData();
      formData.set("costumer_id", this.customerID);
      formData.set("division_id", this.division_id);
      formData.set("carts", JSON.stringify(this.datas));
      this.axios
        .post(
          "/transaction/update/" + this.transaction_number,
          formData,
          config
        )
        .then(() => {
          this.$vloading.hide();
          // console.log(response);
          this.setAlert({
            status: true,
            color: "success",
            text: "Jumlah item pesanan berhasil diubah!",
          });
          this.close();
          this.initialize();
        })
        .catch((error) => {
          this.$vloading.hide();
          // console.log(error.response.data.messages);
          this.setAlert({
            status: true,
            color: "error",
            text: error.response.data.messages,
          });
          this.close();
        });
    },

    cetakBon() {
      // console.log(item.transaction_number);
      // console.log(item.customer[0].full_name);
      // localStorage.route_id = "";
      // localStorage.transaction_id = item.transaction_id;
      // localStorage.transaction_number = item.transaction_number;
      // localStorage.transaction_date = item.transaction_date;
      // // localStorage.status = item.status;
      // localStorage.nip = item.customer[0].nip;
      // localStorage.full_name = item.customer[0].full_name;
      // localStorage.phone = item.customer[0].phone;
      // localStorage.email = item.customer[0].email;
      // localStorage.position_id = item.customer[0].position_name;
      // localStorage.division_id = item.customer[0].division_name;
      // localStorage.section_id = item.customer[0].section_name;
      // localStorage.grade = item.customer[0].grade;
      // localStorage.product = item.total.product;
      // localStorage.qty = item.total.qty;
      this.$router.replace({ name: "Bon" });
    },

    close() {
      this.dialog = false;
      this.dialogSelesai = false;
      this.dialogEdit = false;
    },
  },
};
</script>

<style scoped>
.inputPrice >>> input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice >>> input::-webkit-outer-spin-button,
.inputPrice >>> input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
